<template>
  <layout>
    <input-form class="reset-password-form" mainTitle="短信重置密码">
      <div>
        <input-item
          ref="phone"
          inputPlaceholder="请输入手机号"
          :input-append-operation="`${canSentSms ? '发送验证码' : second }`"
          @operationHandler="sendValidCode"
        />
        <input-item
          ref="validCode"
          inputPlaceholder="请输入验证码"
          :inputPrependIcon="validCodeIcon"
          :inputShowAppend="false"
        />
        <input-item
          ref="password"
          inputPlaceholder="请输入账户密码"
          :inputPrependIcon="passwordIcon"
          :inputShowAppend="false"
          :inputShowPassword="true"
        ></input-item>
      </div>
      <el-button class="reset-password-btn" type="primary" @click="resetPassword">重置密码</el-button>
      <p class="go-login-btn" @click="toLogin">记得密码，马上登录</p>
    </input-form>
    <cap :is-show="validCodeShow" @showReceipt="validResult" />
  </layout>
</template>

<script>
import RegExp from '@/utils/regExp';
import * as ApiResetPwd from '@/api/login';
import Cap from './components/Cap.vue';
import Layout from './components/LoginLayout.vue';
import InputForm from './components/InputForm.vue';
import InputItem from './components/InputItem.vue';

const globalValidCodeIcon = require('@/assets/images/icon_valid_code@3x.png');
const globalPasswordIcon = require('@/assets/images/icon_password@3x.png');

export default {
  name: 'ResetPassword',
  components: {
    Layout,
    InputForm,
    InputItem,
    Cap,
  },
  data() {
    return {
      canSentSms: true,
      second: 60,
      validCodeShow: false,
      validCodeIcon: globalValidCodeIcon,
      passwordIcon: globalPasswordIcon,
      resetPwdForm: {
        phone: '',
      },
    };
  },
  mounted() {
    window.addEventListener('keyup', this.handleKeyUp);
  },
  destoryed() {
    window.removeEventListener('keyup', this.handleKeyUp);
  },
  methods: {
    handleKeyUp(key) {
      if (key.keyCode === 13) {
        this.resetPassword();
      }
    },
    // 重置密码
    resetPassword() {
      const params = {
        phone: this.$refs.phone.getInputValue(),
        code: this.$refs.validCode.getInputValue(),
        password: this.$refs.password.getInputValue(),
        scene: 5,
        source: 1,
      };
      ApiResetPwd.sysResetPassword(params).then((res) => {
        if (res.code === 0) {
          this.$message.messageSuccess('密码重置成功');
          setTimeout(() => {
            this.$router.push('/login');
          }, 500);
        } else {
          this.$message.messageError(res.message);
        }
      });
    },
    toLogin() {
      this.$router.push('/login');
    },
    validResult(val) {
      if (val) {
        this.validCodeShow = false;
        const params = {
          phone: this.$refs.phone.getInputValue(),
          scene: 5,
          source: 1,
        };
        ApiResetPwd.sendSms(params).then((res) => {
          if (res.code === 0) {
            this.$message.messageSuccess('验证码已发送');
            this.timeDown();
          } else {
            this.$message.messageError(res.message);
          }
        });
      }
    },
    sendValidCode() {
      if (!this.canSentSms) return;
      const phone = this.$refs.phone.getInputValue();
      if (!RegExp.phone.test(phone)) {
        this.$message.messageError('请输入正确的手机号');
        return;
      }
      this.validCodeShow = true;
    },
    timeDown() {
      this.canSentSms = false;
      const result = setInterval(() => {
        this.second -= 1;
        if (this.second <= 0) {
          clearInterval(result);
          this.second = 60;
          this.canSentSms = true;
        }
      }, 1000);
    },
  },
};
</script>

<style lang="scss" scoped>
.reset-password-form {
  .input-item {
    width: 80%;
    margin: 20px auto;

    /deep/ .input-prepend-icon {
      width: 24px;
      height: 24px;
    }

    /deep/ .el-input-group__prepend {
      background-color: transparent;
      border: unset;
    }

    /deep/ .el-input-group__append {
      padding: 0 12px;
      font-size: 12px;
      background-color: #409eff;
      color: #ffffff;
    }

    &:nth-child(3) {
      margin-bottom: 0;
    }
  }

  .reset-password-btn {
    width: 80%;
  }

  .go-login-btn {
    font-size: 12px;
    color: #409EFF;

    &:hover {
      cursor: pointer;
    }
  }
}
</style>
